
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: none;
    color: rgb(48, 99, 233);
}

.p-tree {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 12px 0;
    border-radius: 6px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    display: none;
}

.p-treeselect-header {
    display: none;
}