

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: none;
}

.p-multiselect-panel {
    border: 1px solid #9DA7B1;
    margin-top: 4px;
}

.p-multiselect-close-icon {
    display: none;
}

.p-multiselect-panel .p-multiselect-header button {
    display: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: rgb(48, 99, 233);
    background: rgb(48, 99, 233);
    height: 18px;
    width: 18px;
    border-radius: 4px;
    border: 1px;
}
.p-checkbox:hover .p-checkbox-box.p-highlight:hover {
    border-color: rgb(48, 99, 233);
    background: rgb(48, 99, 233);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 14px;
    font-weight: bolder;
}

.p-multiselect-header .p-checkbox::after {
    position: absolute;
    color: #495057;
    content: "Todos";
    margin-left: 28px;
    font-size: 16px;
}

.p-checkbox-checked::before {
    content: "Todos";
    margin-left: 28px;
    position: absolute;
    color: rgb(48, 99, 233);
    background: white;
    font-size: 16px;
    z-index: 2;
}

div.p-multiselect-panel.p-component.p-connected-overlay-enter-done > div.p-multiselect-header > div.p-multiselect-filter-container {
    z-index: 3;
    transition: none;
}

div.p-multiselect-panel.p-component.p-connected-overlay-enter-done > div.p-multiselect-header > div.p-multiselect-filter-container > input {
    margin-left: -2px;
    transition: none;
}

.p-checkbox .p-checkbox-box {
    border: 1px solid rgb(153, 153, 153);
    height: 18px;
    width: 18px;
    border-radius: 4px;
}

.p-multiselect-panel .p-multiselect-header {
    background: none;
    border-bottom: none;
}

.p-multiselect {
   max-width: 263px;
}

.p-multiselect-panel .p-multiselect-items {
    padding-top: 0;
}


.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: white;
    color: rgb(48, 99, 233); 
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}