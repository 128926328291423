body > div.p-dropdown-panel.p-component.p-connected-overlay-enter-done > div > ul > li.p-dropdown-item.p-highlight {
    color: #3069e9;
    font-weight: bold;
    background-color: #edf4fc;
    
}

.p-focus {
    outline: 0 none !important;
    box-shadow: none !important; 
}

body > div.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
    z-index: 9999 !important;
}